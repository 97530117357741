
<i18n locale="th" lang="yaml" >
page.title : "รุ่นของ Drone"
page.description : "แสดงและค้นหารุ่นทั้งหมดของ Drone"

page.section.active : "รุ่นที่ใช้งานอยู่"
page.section.draft : "Draft"
page.section.inactive : "รุ่นที่ยกเลิก"

model_list.draft.remark : "* อยู่ระหว่างกำหนดค่าต่าง ๆ จะยังไม่สามารถเพิ่ม Lot หรือ Drone ได้"
model_list.inactive.remark : "* เป็นรุ่นที่ถูกยกเลิกไปแล้ว"
button.create_model : "สร้าง Drone รุ่นใหม่"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>

		<a-button
			v-if="canCreateModel"
			class="btn-page-action"
			type="primary"
			icon="plus"
			@click="clickCreateModel">
			{{$t('button.create_model')}}
		</a-button>

		<template v-for="models of modelList">
			<div :key="models.key">
				<div class="page-section-title">
					{{$t('page.section.' + models.key)}}
					<span v-if="$notEmpty(models.remark)" class="page-section-description">
						{{models.remark}}
					</span>
				</div>

				<MyCardList
					:num-skeletons="3"
					:empty="models.list.length === 0"
					class="model-list"
					:loading="loading">
					<DroneModelLiteCard
						slot="skeleton"
						:bordered="true"
						:loading="true" />
					<DroneModelLiteCard v-for="model of models.list"
						:key="model.id"
						:model="model"
						:bordered="true"
						:clickable="true"/>
				</MyCardList>

			</div>
		</template>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import MyCardList from "@components/common/MyCardList.vue"
import DroneModelLiteCard from "@components/drone/DroneModelLiteCard.vue"

export default {
	components : {
		MyCardList , DroneModelLiteCard
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	mixins : [PageMixin],
	data() {
		return {
			modelList : [] ,
			loading: false,
		}
	} ,
	computed: {
		canCreateModel() {
			return this.$authorize('create','droneModel')
		} ,
		canViewDraft() {
			return this.$authorize('readDraft','droneModel')
		},
		canViewInactive() {
			return this.$authorize('readDraft','droneModel')
		} ,
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		clickCreateModel() {
			this.$router.push({name : "drone_model/create"});
		} ,
		fetchData() {
			this.loading = true
			axios.get("/api/drone-models/").then((response) => {
				const models = response.data.data.drone_models
				this.modelList = [
					{
						key : 'active' ,
						list : models.filter((model) => model.status == 'active')
					}
				];
				if (this.canViewDraft) {
					const draftList = models.filter((model)=>model.status=='draft')
					if (draftList.length > 0) {
						this.modelList.push({
							key : 'draft',
							list : draftList,
							remark : this.$t('model_list.draft.remark')
						})
					}
				}
				if (this.canViewInactive) {
					const inactiveList = models.filter((model)=>model.status=='inactive')
					if (inactiveList.length >0) {
						this.modelList.push({
							key : 'inactive',
							list : inactiveList,
							remark : this.$t('model_list.inactive.remark')
						})
					}
				}
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
.model-list::v-deep {
	margin-bottom : 32px;
	.drone-model-card {
		width : 400px;
	}
}
</style>
