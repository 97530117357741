<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_model.field.status.draft.tooltip : "กำลังใส่ข้อมูลอยู่ ยังไม่สามารถเพิ่ม Lot หรือ ระบุ Drone ได้"
</i18n>

<template>
	<a-card
		:loading="loading"
		:hoverable="cClickable"
		:bordered="bordered"
		:class="cardCssClasses"
		@click="handleClick">
		<div class="mycard-content">
			<div class="mycard-header">
				<my-router-link name="drone_model/view" :param-value="model.id" :target="linkTarget" auth-action="read" auth-module="droneModel"
					@click.stop>
					<ImageLoader
						class="mycard-image"
						:src="model.image_url"
						:size="size"
						border-type="round">
						<DroneIcon class="anticon" />
					</ImageLoader>
				</my-router-link>
			</div>
			<ul class="mycard-details model-details">
				<li class="title text-bold">
					{{model.name}}
				</li>
				<li class="model-display-name">
					({{model.display_name}})
				</li>
				<li>
					{{$t('drone_model.field.drone_type')}} : {{$tenum('drone_type',model.drone_type)}}
				</li>
				<li>
					{{$t('drone_model.field.firmware_set')}} : {{model.firmwareSet ? model.firmwareSet.name : $t('common.unspecify')}}
				</li>
				<li>
					{{$t('drone_model.field.release_date')}} : {{model.release_date | date}}
				</li>
				<li class="mycard-tags">
					<MyTooltip
						v-show="model.status === 'draft'"
						:title="$t('drone_model.field.status.draft.tooltip')">
						<a-tag  class="mytag-primary"  @click.stop>Draft</a-tag>
					</MyTooltip>
				</li>
			</ul>
		</div>
		<div  class="mycard-invalid-status">
			<span v-if="model.status === 'inactive'">
				<a-icon  type="close-circle" />
				{{$tenum('drone_model.status','inactive')}}
			</span>
		</div>
	</a-card>
</template>

<script>
import DroneIcon from "@components/icon/DroneIcon.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import {Tag} from "ant-design-vue"
import MyTooltip from "@components/common/MyTooltip.vue"
export default {
	components : {
		ImageLoader,
		"a-tag" : Tag, MyTooltip ,DroneIcon
	} ,
	props : {
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		model : {
			type : null,
			default : () => []
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		clickable :{
			type : Boolean,
			default : false,
		}
	},

	computed : {
		cClickable() {
			return this.clickable && this.$authorize("read", "droneModel")
		},
		cardCssClasses() {
			return [
				"drone-model-card","mycard","mycard-columns","mycard-with-details",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-inactive" : this.model.status === 'inactive' ,
				}
			]
		} ,
	} ,
	methods : {
		handleClick(event) {
			if (!this.cClickable)
				return;
			this.$open({name:'drone_model/view',params:{id : this.model.id}},this.linkTarget)
		} ,
	}
}
</script>
<style lang="less" scoped>
	ul.model-details > li {
		font-size : 0.95em;
		margin-bottom : 4px;
	}
	.model-display-name {
		color : @text-muted;
		font-size : 0.9em;
		margin-top : -4px;
	}
</style>
