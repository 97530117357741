<script>
export default {
	props : {
		appendBody : {
			type : Boolean,
			default : false
		}
	} ,
	methods : {
		getPopupContainer(trigger) {
			if (this.appendBody)
				return document.body
			else {
				const mainContent = document.querySelector("#tunjai_content")
				return mainContent || trigger.parentElement;
			}
    },
	}
}
</script>
