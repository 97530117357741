<i18n locale="en" lang="yaml">
default.empty : "No Data Found"
</i18n>
<i18n locale="th" lang="yaml" >
default.empty : "ไม่พบข้อมูล"
</i18n>

<template>
	<div :class="cssClasses">
		<template v-if="loading">
			<template v-if="hasSlotSkeleton">
				<slot v-for="i in numSkeletons" name="skeleton" :data-index="i" class="mycard"></slot>
			</template>
			<template v-else>
				<slot name="loading" />
			</template>
		</template>
		<slot v-else-if="empty" name="empty">
			<a-empty>
				<span slot="description">{{cEmptyMessage}}</span>
			</a-empty>
		</slot>
		<slot v-else>
		</slot>
	</div>
</template>

<script>
import {isStringEmpty} from "@utils/stringUtil.js"
import {Empty} from "ant-design-vue"
export default {
	components : {
		"a-empty" : Empty,
	} ,
	props : {
		loading : {
			type : Boolean ,
			default : false ,
		} ,
		empty : {
			type : Boolean ,
			default : false ,
		} ,
		emptyMessage : {
			type : String ,
			default : ""
		} ,
		numSkeletons : {
			type: Number ,
			default : 3 ,
		} ,
		vertical : {
			type : Boolean ,
			default : false
		}
	} ,
	computed : {
		cssClasses() {
			return [
				'mycard-list' ,
				{
					'mycard-list-vertical' : this.vertical
				}
			]
		} ,
		cEmptyMessage() {
			return isStringEmpty(this.emptyMessage) ? this.$t('default.empty') : this.emptyMessage
		} ,
		hasSlotSkeleton() {
			return this.$slots.skeleton != null
		} ,
		hasSlotLoading() {
      return this.$slots.loading != null;
    } ,
	}
}
</script>
